import { Link } from 'react-router-dom';
import Header from '../layouts/Header';

const DataSecurity = () => {
   return (
      <>
         <Header />
         <div className="bg-[#E1F3F5] min-h-screen">
            <div className="container max-w-4xl mx-auto px-4 py-8 sm:py-12 font-poppins">
               <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 sm:mb-8">
                  <span className="text-[#093620]">Data Security Policy</span>
               </h1>
               <div className="space-y-6 sm:space-y-8">
                  <section>
                     <p className="text-gray-700 mb-4 text-xs sm:text-sm">
                        This Data Security Policy ("Policy") has been enforced by the board decision and have been prepared by "Sustaincred Solutions Private Limited" and Data Controller to fulfill obligations of the Company according to applicable data security law and regulations and the secondary legislation and determining the principles of storage for the purpose of processing data.
                     </p>
                     <p className="text-gray-700 mb-4 text-xs sm:text-sm">
                        Within the framework of this policy, data means any digital or physical information that is collected, processed, stored or transmitted by our Company as the data controller. The scope of data covered by data security policies can include personally identifiable information, such as names, addresses, social security numbers, financial data, health records, or any other information that can be used to identify an individual. It can also encompass intellectual property, trade secrets, customer data, employee records, or any other sensitive or valuable information that an organization possesses.
                     </p>
                  </section>

                  <section>
                     <h2 className="text-lg sm:text-xl md:text-2xl font-semibold text-[#093620] mb-4">General Consideration on Data Privacy</h2>
                     <p className="text-gray-700 mb-4 text-xs sm:text-sm">
                        The sustaincred acts within the framework of the following principles in the storage and disposal of the data:
                     </p>
                     <ul className="list-disc pl-5 sm:pl-8 mb-4 text-gray-700 text-xs sm:text-sm">
                        <li className="mb-2">All activities carried out by the Company were analyzed in detail for all business units, and as a result of this analysis, a process-based data processing inventory was prepared.</li>
                        <li className="mb-2">Data Processing activities carried out by the Company are audited by information security systems, technical systems and legal methods. The Company gets outsourcing services for technical issues.</li>
                        <li className="mb-2">The Company being acted completely complied with the law and provisions of the relevant legislation, Board decisions and the provisions of this policy in the operation of erasure, disposal and anonymization of the data.</li>
                        <li className="mb-2">All transactions regarding the erasure, disposal and anonymization of personal data are recorded by Sustaincred and these records are kept for at least 10 years, excluding other legal obligations.</li>
                        <li className="mb-2">The appropriate ways of the erasure, disposal and / or anonymization of the data will be selected on its own motion by the Company unless a contrary decision is taken by the Board. However, upon the request of the data subject (owner of the data), the appropriate method will be chosen by explaining its justification.</li>
                        <li className="mb-2">Data processors are informed about the applicable laws regarding data protection and data privacy and required measures. Non-Disclosure Agreements have been signed with all data processors and provisions regarding personal data protection will be added in the future-contracts.</li>
                        <li className="mb-2">Access authorizations are limited, authorizations are reviewed regularly. The software and hardware, which includes virus protection systems and defensive walls, are being set.</li>
                        <li className="mb-2">Back-up programs are being used to ensure that personal data is kept securely.</li>
                        <li className="mb-2">Security systems are used for the storage areas, the technical measures taken are periodically reported to the relevant person in accordance with the internal controls, the risky issues are reevaluated and necessary technological solutions are produced. The documents/printouts in physical areas are saved and then disposed according to disposal policy.</li>
                     </ul>
                  </section>

                  <section>
                     <h2 className="text-lg sm:text-xl md:text-2xl font-semibold text-[#093620] mb-4">Principles of Processing the data</h2>
                     <p className="text-gray-700 mb-4 text-xs sm:text-sm">
                        All of the Personal data processed by Company are being processed pursuant to data privacy laws and related legislation. Pursuant to applicable law, the Sustaincred processes in a limited and measured manner in connection with the purpose, pursuant to the law and the principle of good faith, accurate and, when necessary up-to-date, specific, clear and with legitimate purposes.
                     </p>
                     <p className="text-gray-700 mb-4 text-xs sm:text-sm">
                        The Company; acts in accordance with principles introduced by legal regulations and the general principle of good faith in the processing of personal data. In this context, the proportionality requirements are being considered by the Company, and personal data are not used regardless of its required purpose.
                     </p>
                     <p className="text-gray-700 mb-4 text-xs sm:text-sm">
                        The Company determines clearly and precisely its legitimate and lawful purpose for processing personal data. The Company processes personal data to the extent necessary and in connection with the products and services it offers. The purpose for which personal data will be processed by the Company is set out before the personal data processing activity begins.
                     </p>
                  </section>

                  <section>
                     <h2 className="text-lg sm:text-xl md:text-2xl font-semibold text-[#093620] mb-4">The Reasons of Storing</h2>
                     <p className="text-gray-700 mb-4 text-xs sm:text-sm">
                        The data defined in this Policy are sorted by the Company within the limits specified in the Law and other relevant legislation, especially for (i) to continue commercial activities, (ii) to fulfill legal obligations, (iii) to plan and fulfill employee rights and side benefits.
                     </p>
                     <p className="text-gray-700 mb-4 text-xs sm:text-sm">The reasons of storage as follows:</p>
                     <ul className="list-disc pl-5 sm:pl-8 mb-4 text-gray-700 text-xs sm:text-sm">
                        <li className="mb-2">Storing the personal data as it is directly related to the establishment and performance of contracts,</li>
                        <li className="mb-2">Storing the personal data for the purpose of establishing, exercising or protecting of a right,</li>
                        <li className="mb-2">Storing the personal data when it is mandatory to store for the legitimate interests of the Company, under the condition that not harm fundamental rights and freedoms of individuals,</li>
                        <li className="mb-2">Storing the personal data for the purpose of fulfillment of the Company's any kind of legal obligation.</li>
                        <li className="mb-2">The storage of personal data clearly foreseen in the legislation,</li>
                        <li className="mb-2">Storing the personal data pursuant to the explicit consent of the data owners, when explicit consent is required.</li>
                     </ul>
                     <p className="text-gray-700 mb-4 text-xs sm:text-sm">
                        The following criteria are used to determine the storing and disposal periods of your personal data obtained by the Company in accordance with the Law and other relevant legislation provision. If a period of time is stipulated in the legislation regarding the storage of the personal data, this period shall be complied with. Periodic disposal is carried out twice a year. The first periodical disposal operations are carried out in the time of the first disposal of each year, and the second is carried out time of the second disposal of each year.
                     </p>
                  </section>

                  <section>
                     <h2 className="text-lg sm:text-xl md:text-2xl font-semibold text-[#093620] mb-4">Methods, Technical and Administrative Precautions</h2>
                     <p className="text-gray-700 mb-4 text-xs sm:text-sm">
                        In the situations of, if it is required to fulfill obligations regarding employment obligations of the Company, when processing data is mandatory to fulfill a right, for benefiting consumer rights and other opportunities and/or fulfill commercial and legal obligations are related to these rights and opportunities, to ensure the physical security of the Company or if there are legitimate interests of the Company, all the data are being stored in servers of the Company.
                     </p>
                     <p className="text-gray-700 mb-4 text-xs sm:text-sm">
                        In the scope of administrative measures taken by the Company, restrictions applied for internal access to stored data to the employee who is required to access it as per job description. While restricting access, sensitive data and its importance are also considered. If other individuals obtain the processed personal data unlawfully, notification is made to the relevant person and the Board as soon as possible.
                     </p>
                     <p className="text-gray-700 mb-4 text-xs sm:text-sm">
                        Regarding the transfer of personal data, data security is provided by signing a framework agreement on the protection of personal data and data security with the persons to whom personal data is shared or by the provisions added to the existing contract. Experienced employees are employed and they are informed about the processing of personal data.
                     </p>
                     <p className="text-gray-700 mb-4 text-xs sm:text-sm">
                        In the scope of technical measures, The Company makes required internal controls within the scope of established systems, The Company also conducts the processes of information technology risk assessment and business impact analysis within the scope of established systems. The technical infrastructure is ensured to prevent or monitor the leakage of data outside of the institution and the creation of relevant matrices. The Company also ensures the access to personal data of employees in information technology units is kept under control.
                     </p>
                     <p className="text-gray-700 mb-4 text-xs sm:text-sm">
                        This policy regarding the data security approved by the Board of Directors of the Company.
                     </p>
                  </section>
               </div>

               <div className="mt-8 sm:mt-12">
                  <Link to="/" className="inline-flex items-center bg-[#2B6E2B] text-white font-medium py-2 px-4 sm:py-3 sm:px-6 rounded-full hover:bg-green-800 transition duration-300 text-xs sm:text-sm">
                     Back to Home
                  </Link>
               </div>
            </div>
         </div>
      </>
   );
};

export default DataSecurity;